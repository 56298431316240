import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'auth',
    resource: 'roles',
    prefix: 'auth.role_list',
    prefix_state: 'auth_role_list',
    paginate: false
  },
  {
    default_query: {
      include: "permissionsCount,usersCount",
    }
  },
).store()

export default resource
