import resources from "./auth/resources"
import permissions_role from "./auth/permissions_role"
import role_list from "./auth/role_list"
import posts from "./helper/posts"

export default {
  resources,
  permissions_role,
  role_list,
  posts,
};
