import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'helper',
    resource: 'posts',
  },
  {
    default_query: {
      include: "categories",
      append: "creator",
      sort: "-created_at"
    },
    detail_query: {
      include: "categories",
      append: "creator",
    }
  },
).store()

export default resource
