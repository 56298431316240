export default [
  {
    _name: "TSidebarNavItem",
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: "cil-speedometer",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Users",
  },
  {
    _name: "TSidebarNavItem",
    name: "Users",
    to: "/admin/users",
    icon: "cil-user",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Group management",
  },
  {
    _name: "TSidebarNavItem",
    name: "Role",
    to: "/admin/roles",
    icon: "cil-people",
  },
  {
    _name: "TSidebarNavItem",
    name: "Organization",
    to: "/admin/organizations",
    icon: "cil-timeline",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Goods",
  },
  {
    _name: "TSidebarNavItem",
    name: "Product",
    to: "/admin/goods/products",
    icon: "cil-chart-table",
  },
  {
    _name: "TSidebarNavItem",
    name: "Category",
    to: "/admin/goods/categories",
    icon: "cil-view-list",
  },
  {
    _name: "TSidebarNavItem",
    name: "Origin",
    to: "/admin/goods/origins",
    icon: "cil-toll",
  },
  {
    _name: "TSidebarNavItem",
    name: "Tag",
    to: "/admin/goods/tags",
    icon: "cil-tags",
  },
  {
    _name: "TSidebarNavItem",
    name: "Price",
    to: "/admin/goods/prices",
    icon: "cil-dollar-circle",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Ecommerce channel",
  },
  {
    _name: "TSidebarNavItem",
    name: "Ecommerce channel",
    to: "/admin/ecommerce/ecommerce-channel",
    icon: "cil-globe",
  },
  {
    _name: "TSidebarNavItem",
    name: "Affiliate store",
    to: "/admin/ecommerce/affiliate-store",
    icon: "cil-store",
  },
  {
    _name: "TSidebarNavItem",
    name: "Affiliate category",
    to: "/admin/ecommerce/affiliate-category",
    icon: "cil-view-list",
  },
  {
    _name: "TSidebarNavItem",
    name: "Affiliate product",
    to: "/admin/ecommerce/affiliate-product",
    icon: "cil-chart-table",
  },
  {
    _name: "TSidebarNavItem",
    name: "Tag",
    to: "/admin/ecommerce/affiliate-product-tags",
    icon: "cil-tags",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Management",
  },
  {
    _name: "TSidebarNavItem",
    name: "Address",
    to: "/admin/addresses",
    icon: "cil-view-list",
  },
  {
    _name: "TSidebarNavItem",
    name: "Post",
    to: "/admin/posts",
    icon: "cisLibraryBooks",
  },
  {
    _name: "TSidebarNavItem",
    name: "Post category",
    to: "/admin/post-categories",
    icon: "cilLibraryBookmark",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Support",
  },
  {
    _name: "TSidebarNavItem",
    name: "Support",
    to: "/admin/supports",
    icon: "cil-comment-bubble-question",
  },
  {
    _name: "TSidebarNavItem",
    name: "Support types",
    to: "/admin/support-types",
    icon: "cil-chat-bubble",
  },
  {
    _name: "TSidebarNavItem",
    name: "Subscribe notification",
    to: "/admin/notification-subscriber",
    icon: "cil-bell-plus",
  },
];
