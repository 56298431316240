import Resource from "@/core/store/Resource";

const resource = new Resource(
  {
    service: "auth",
    resource: "permissions",
    prefix: 'auth.permissions_role',
    prefix_state: 'auth_permissions_role',
    paginate: false
  },
  {
    default_query: {
      "filter[object_type]": 'role',
    }
  },
).store();

export default resource;
